import a_a_transporturi from "./assets/logos/A & A TRANSPORTURI SPECIALE.png";
import a_v_v_cargotrans from "./assets/logos/A.V.V. CARGOTRANS_creat de mine.JPG";
import agexim from "./assets/logos/AGEXIM SPEDITION.png";
import ahead from "./assets/logos/ahead Logistics 3.jpg.crdownload.jpg";
import alviona from "./assets/logos/Alviona.jpg";
import anasped from "./assets/logos/ANASPED_02 copy.jpg";
import andgeros from "./assets/logos/Andgeros.jpg";
import aotr from "./assets/logos/AOTR.png";
import apte from "./assets/logos/APTE.jpg";
import aquila from "./assets/logos/Aquila+LOGO.png";
import arabesque from "./assets/logos/Arabesque.png";
import arcese from "./assets/logos/Arcese.png";
import asexpress from "./assets/logos/ASEXPRESS.jpg";
import auto_fug from "./assets/logos/AUTO FUG_rosu.png";
import avis_trans from "./assets/logos/AVIS TRANS.png";
import bcvo from "./assets/logos/BCVO.jpg";
import blue_river from "./assets/logos/BLUE RIVER.png";
import bravia from "./assets/logos/BRAVIA.jpg";
import cargo_max from "./assets/logos/CARGO MAX_Creat de mine.JPG";
import cargo_trans from "./assets/logos/CARGO TRANS.png";
import cargoways from "./assets/logos/Cargoways Logistik& Transport.png";
import carluc from "./assets/logos/CARLUC GEOLA.JPG";
import carrion from "./assets/logos/Carrion EXPEDITION.png";
import cartrans from "./assets/logos/Cartrans ROMANIA.png";
import catadi from "./assets/logos/CATADI TRANS.jpg";
import ceres_pan from "./assets/logos/CERES PAN_creat de mine.JPG";
import ch_robinson from "./assets/logos/CH ROBINSON.png";
import clm_trans from "./assets/logos/CLM TRANS.png";
import com_divers_auto from "./assets/logos/COM DIVERS AUTO RO.png";
import consultant from "./assets/logos/CONSULTANT_creat de mine.JPG";
import corlan from "./assets/logos/CORLAN TRANS.jpg";
import crini_trans from "./assets/logos/CRINI TRANS_negruok.png";
import csm from "./assets/logos/CSM Universal Transport.png";
import dalocris from "./assets/logos/DALOCRIS.png";
import danycop from "./assets/logos/DANYCOP TRANS.png";
import delclaudys from "./assets/logos/DELCLAUDYS.png";
import deli_trans from "./assets/logos/DELI TRANS.png";
import dexell from "./assets/logos/DEXELL SPEDITION.png";
import dianthus from "./assets/logos/DIANTHUS COMPANY.jpg";
import donatiro from "./assets/logos/DONATIRO_2.png";
import drumar from "./assets/logos/DRUMAR OVY CONSTRUCT_creat de mine.JPG";
import dsp from "./assets/logos/DSP EUROTRANS_creat de mine.JPG";
import dsv from "./assets/logos/DSV.png";
import dumagas from "./assets/logos/DUMAGAS TRANSPORT.png";
import dunca from "./assets/logos/DUNCA EXPEDITII.jpg";
import duvenbeck from "./assets/logos/DUVENBECK LOGISTIK.png";
import dya from "./assets/logos/DYA MARKET.png";
import east from "./assets/logos/EAST CARGOLOG.png";
import ekol from "./assets/logos/Ekol International Logistics.png";
import eu_ro from "./assets/logos/EU RO SPEED.png";
import eurohaul from "./assets/logos/EUROHAUL SERVICES.png";
import european_tractioner from "./assets/logos/EUROPEAN TRACTIONER SPECIALIST.png";
import evan from "./assets/logos/EVAN SPEED LOGISTIC.jpg";
import ew from "./assets/logos/EW LOGISTICS.png";
import exclusiv from "./assets/logos/EXCLUSIV CAR TRADING.png";
import express from "./assets/logos/EXPRESS EUROSCAN.jpg";
import filadelfia from "./assets/logos/FILADELFIA.PNG";
import filip from "./assets/logos/FILIP.PNG";
import fir from "./assets/logos/FIR EXPRESS_creat de mine.JPG";
import first from "./assets/logos/FIRST DELI GROUP_creat de mine.JPG";
import frigorificos from "./assets/logos/FRIGORIFICOS DANIEL.png";
import galedo from "./assets/logos/Galedo.png";
import gatt from "./assets/logos/GATT TRANSPORT & LOGISTIK.png";
import giro from "./assets/logos/GIRO.png";
import gopet from "./assets/logos/GOPET.png";
import gp from "./assets/logos/GP INTERMODAL.jpg";
import gribex from "./assets/logos/Gribex.png";
import grimar from "./assets/logos/GRIMAR AUTO CEZAR.png";
import gruber from "./assets/logos/GRUBER LOGISTICS.png";
import gs from "./assets/logos/GS CAPITAL.png";
import hagero from "./assets/logos/Hagero.png";
import hartmann from "./assets/logos/HARTMANN PRODCOM.png";
import hedvig from "./assets/logos/HEDVIG IMPEX_creat de mine.JPG";
import hermes from "./assets/logos/HERMES FORWARDS SRL.png";
import hessers from "./assets/logos/HESSERS.png";
import hoedlmayr from "./assets/logos/HOEDLMAYR-LAZĂR ROMÂNIA.png";
import ib from "./assets/logos/IB CARGO.png";
import inredning from "./assets/logos/INREDNING_creat de mine.JPG";
import alexander from "./assets/logos/INTERNATIONAL ALEXANDER.png";
import lazar from "./assets/logos/International Lazar Company.png";
import isctr from "./assets/logos/ISCTR.png";
import kaufland from "./assets/logos/KAUFLAND.png";
import kefren from "./assets/logos/KEFREN EXPRES.png";
import klg from "./assets/logos/KLG Europe Logistics.png";
import kojex from "./assets/logos/KOJEX TRANS_creat de mine.JPG";
import kpmg from "./assets/logos/KPMG.png";
import laguna from "./assets/logos/LAGUNA TECHNOLOGY.png";
import lg from "./assets/logos/LG TRANS.png";
import lkw from "./assets/logos/LKW WALTER.png";
import luar from "./assets/logos/LUAR.png";
import mcms from "./assets/logos/M.C.M.S. BOTA.png";
import mg from "./assets/logos/M.G. EUROTRANSPORT LOGISTIK.png";
import mainfreight from "./assets/logos/MAINFREIGHT.png";
import maioma from "./assets/logos/MAIOMA LOGISTICS_creat de mine.JPG";
import man from "./assets/logos/MAN TRANSPORT SERVICE.jpeg";
import mara from "./assets/logos/MARA FREIGHT.png";
import marvi from "./assets/logos/MARVI LOGISTIC.png";
import marvicon from "./assets/logos/MARVICON.png";
import mastogaf from "./assets/logos/MASTOGAF.png";
import maxim from "./assets/logos/MAXIM INVEST.png";
import mega from "./assets/logos/MEGA IMAGE.png";
import minet from "./assets/logos/MINET LOGISTICS SRL.png";
import minumar from "./assets/logos/MINUMAR_creat de mine.JPG";
import mirinec from "./assets/logos/MIRINEC.png";
import ms from "./assets/logos/MS PROJECT.png";
import natylena from "./assets/logos/NATYLENA LOGISTIC_creat de mine.JPG";
import nett from "./assets/logos/NETT '95 IMPEX_creat de mine.JPG";
import obermair from "./assets/logos/OBERMAIR SONDERTRANSPORTE.png";
import one from "./assets/logos/ONE HUB MANAGEMENT.png";
import oprean from "./assets/logos/OPREAN.png";
import optim from "./assets/logos/OPTIM CARGO SPED.jpg";
import oramar from "./assets/logos/ORAMAR COM.png";
import orient from "./assets/logos/ORIENT SRL.png";
import orkla from "./assets/logos/ORKLA FOODS.jpg";
import orzan from "./assets/logos/ORZAN-SPEDT.png";
import oxygen from "./assets/logos/OXYGEN LOGISTIC_creat de mine.JPG";
import pall from "./assets/logos/PALL-EX.png";
import paritrans from "./assets/logos/PARITRANS.JPG";
import pdd from "./assets/logos/PDD DENMARK LOGISTIC.png";
import petco from "./assets/logos/Petco.png";
import queen from "./assets/logos/QUEEN TRANS_creat de mine.JPG";
import ragioneasa from "./assets/logos/RAGINOASA TRANSIMPEX_creat de mine.JPG";
import ravitex from "./assets/logos/RAVITEX.png";
import ravy from "./assets/logos/RAVY PREST.png";
import rcc from "./assets/logos/RCC CARGO.png";
import renylog from "./assets/logos/RENYLOG.png";
import rhv from "./assets/logos/RHV TRUCKS SPEDITION.jpg";
import rolling from "./assets/logos/ROLLING TONS.jpg";
import romdyl from "./assets/logos/ROMDYL SPEDITION_creat de mine.JPG";
import romfracht from "./assets/logos/ROMFRACHT.png";
import romstyl from "./assets/logos/ROMSTYL IMPEX.png";
import rontransmar from "./assets/logos/RONTRANSMAR.png";
import rostel from "./assets/logos/ROSTEL AUTO_creat de mine.JPG";
import routier from "./assets/logos/ROUTIER EUROPEAN TRANSPORT.png";
import rowo from "./assets/logos/ROWO SPEDITION SRL.png";
import rupin from "./assets/logos/RUPIN_creat de mine.JPG";
import sgs from "./assets/logos/SAFE GUARD SPEDITION.png";
import safir from "./assets/logos/SAFIR.png";
import salmex from "./assets/logos/SALMEX MP TRANS _creat de mine.JPG";
import sandor from "./assets/logos/SANDOR TRANS.jpg";
import schaffer from "./assets/logos/SCHAFFER CONSULTING.jpg";
import scuter from "./assets/logos/SCUTER PROF.jpg";
import sennder from "./assets/logos/SENNDER.png";
import sima from "./assets/logos/SIMA_creat de mine.JPG";
import skiptrans from "./assets/logos/SKIPTRANS.png";
import sms from "./assets/logos/SMS TRANS.JPG";
import speed from "./assets/logos/SPEED LINE IMPEX_2.png";
import step from "./assets/logos/STEP ONE.jpg";
import tandem from "./assets/logos/TANDEM INTERTRANS_creat de mine.JPG";
import tarm from "./assets/logos/TARM FREIGHT COM_creat de mine.JPG";
import tdt from "./assets/logos/TDT TRANS LOGISTIC SRL.jpg";
import ten from "./assets/logos/TEN-TRANS.png";
import theo from "./assets/logos/THEO & WENZEL S.R.L..jpg";
import timmy from "./assets/logos/TIMMY FREIGHT_creat de mine.JPG";
import total from "./assets/logos/TOTAL NSA.jpg";
import total2 from "./assets/logos/TOTAL NSA_2.png";
import tramos from "./assets/logos/TRAMOS LOGISTIC.png";
import trans_logistic from "./assets/logos/TRANS LOGISTIC N.A.D..jpg";
import trans_men from "./assets/logos/trans men 86_alb.png";
import trans_neco from "./assets/logos/TRANS NECO_creat de mine.JPG";
import trans_orza from "./assets/logos/TRANS ORZA.JPG";
import trans_tur from "./assets/logos/TRANS TUR ARDELEAN_creat de mine.JPG";
import transagal from "./assets/logos/TRANSAGAL LOGISTIC_creat de mine.JPG";
import transfabi from "./assets/logos/TRANSFABI LOG.png";
import transmec from "./assets/logos/TRANSMEC GROUP.png";
import trans_pink from "./assets/logos/TRANSPINK.png";
import transportes from "./assets/logos/TRANSPORTES-FILIP.png";
import transsami from "./assets/logos/TRANSSAMI.jpg";
import trucksters from "./assets/logos/TRUCKSTERS_negru.png";
import tutu from "./assets/logos/TUTU-TRANS.jpg";
import united from "./assets/logos/UNITED EURO SPEDITION_creat de mine.JPG";
import uni from "./assets/logos/UNITRANS LOGISTICS.png";
import vectra from "./assets/logos/VECTRA INTERNATIONAL.png";
import vio from "./assets/logos/VIO TRANSGRUP.png";
import way from "./assets/logos/WAY FARER.png";
import wise from "./assets/logos/WISE.JPG";
import world from "./assets/logos/WORLD MEDIATRANS.png";
import a_c_speed_line from "./assets/logos/A & C SPEED LINE_creat_nou.JPG";
import ac_mobile from "./assets/logos/AC MOBILE_nou.png";
import aigle from "./assets/logos/AIGLE GROUP_nou.png";
import alfa from "./assets/logos/ALFA CAR_nou.png";
import altec from "./assets/logos/ALTEC LOGISTIC SRL_nou.png";
import atlas from "./assets/logos/ATLAS IMOBILIARE_nou.png";
import blema from "./assets/logos/BLEMA TRANS_nou.png";
import blue from "./assets/logos/BLUE MOON TRUCKS_Nou.JPG";
import borgovan from "./assets/logos/BORGOVAN TRANS IMPEX_nou.JPG";
import comilga from "./assets/logos/COMILGA_nou.JPG";
import csi from "./assets/logos/CSI LOGISTIC_nou.png";
import daralex from "./assets/logos/DARALEX TRANS_nou.JPG";
import db from "./assets/logos/DB SCHENKER_nou.png";
import design from "./assets/logos/DESIGN THINKING SOCIETY_nou.jpg";
import energy from "./assets/logos/ENERGY FAST N&G TRANSPORT_nou.JPG";
import erk from "./assets/logos/ERK LOGISTIC IMPEX_nou.JPG";
import galilex from "./assets/logos/GALILEX FOREST_nou.png";
import gefco from "./assets/logos/GEFCO ROMANIA_nou.png";
import heisterkamp from "./assets/logos/HEISTERKAMP TRANSPORT ROMANIA_nou.png";
import hydras from "./assets/logos/HYDRAS_nou.png";
import infinity from "./assets/logos/INFINITY COMPROD_nou.png";
import innight from "./assets/logos/INNIGHT ROMANIA EXPRESS_nou.JPG";
import kraft from "./assets/logos/KRAFT LOGISTIK_nou.png";
import murano from "./assets/logos/MURANO TRANS EXPRES_nou.png";
import nordman from "./assets/logos/NORDMAN BAUM_nou.png";
import ravcar from "./assets/logos/RAVCAR_nou.png";
import rhenus from "./assets/logos/RHENUS LOGISTIC_nou.jpg";
import sam from "./assets/logos/SAM TRANSPORT_nou.jpg";
import semiramis from "./assets/logos/SEMIRAMIS_nou.JPG";
import sorlimar from "./assets/logos/Sorlimar_nou.JPG";
import stadtler from "./assets/logos/STADTLER TRANS_nou.png";
import tranzit from "./assets/logos/Tranzit_nou.JPG";

export const logoOptions = [
  {
    value: a_a_transporturi,
    label: "A&A Transporturi Speciale",
    image: a_a_transporturi,
  },
  {
    value: a_v_v_cargotrans,
    label: "A.V.V. CargoTrans",
    image: a_v_v_cargotrans,
  },
  {
    value: agexim,
    label: "Agexim Spedition",
    image: agexim,
  },
  {
    value: ahead,
    label: "Ahead Logistic",
    image: ahead,
  },
  {
    value: alviona,
    label: "Alviona",
    image: alviona,
  },
  {
    value: anasped,
    label: "Anasped",
    image: anasped,
  },
  {
    value: andgeros,
    label: "Andgeros",
    image: andgeros,
  },
  {
    value: aotr,
    label: "AOTR",
    image: aotr,
  },
  {
    value: apte,
    label: "APTE",
    image: apte,
  },
  {
    value: aquila,
    label: "Aquila",
    image: aquila,
  },
  {
    value: arabesque,
    label: "Arabesque",
    image: arabesque,
  },
  {
    value: arcese,
    label: "Arcese",
    image: arcese,
  },
  {
    value: asexpress,
    label: "ASEXPRESS",
    image: asexpress,
  },
  {
    value: auto_fug,
    label: "Auto Fug",
    image: auto_fug,
  },
  {
    value: avis_trans,
    label: "Avis Trans",
    image: avis_trans,
  },
  {
    value: bcvo,
    label: "BCVO",
    image: bcvo,
  },
  {
    value: blue_river,
    label: "Blue River",
    image: blue_river,
  },
  {
    value: bravia,
    label: "Bravia",
    image: bravia,
  },
  {
    value: cargo_max,
    label: "Cargo Max",
    image: cargo_max,
  },
  {
    value: cargo_trans,
    label: "Cargo Trans",
    image: cargo_trans,
  },
  {
    value: cargoways,
    label: "Cargo ways",
    image: cargoways,
  },
  {
    value: carluc,
    label: "Carluc Geola",
    image: carluc,
  },
  {
    value: carrion,
    label: "Carrion Expedition",
    image: carrion,
  },
  {
    value: cartrans,
    label: "Cartrans Romania",
    image: cartrans,
  },
  {
    value: catadi,
    label: "Catadi Trans",
    image: catadi,
  },
  {
    value: ceres_pan,
    label: "Ceres Pan",
    image: ceres_pan,
  },
  {
    value: ch_robinson,
    label: "CH Robinson",
    image: ch_robinson,
  },
  {
    value: clm_trans,
    label: "CLM Trans",
    image: clm_trans,
  },
  {
    value: com_divers_auto,
    label: "COM Divers Auto Ro",
    image: com_divers_auto,
  },
  {
    value: consultant,
    label: "Consultant",
    image: consultant,
  },
  {
    value: corlan,
    label: "Corlan Trans",
    image: corlan,
  },
  {
    value: crini_trans,
    label: "Crini Trans",
    image: crini_trans,
  },
  {
    value: csm,
    label: "CSM Universal Transport",
    image: csm,
  },
  {
    value: dalocris,
    label: "Dalocris",
    image: dalocris,
  },
  {
    value: danycop,
    label: "Danycop Trans",
    image: danycop,
  },
  {
    value: delclaudys,
    label: "DelClaudys",
    image: delclaudys,
  },
  {
    value: deli_trans,
    label: "Deli Trans",
    image: deli_trans,
  },
  {
    value: dexell,
    label: "Dexell Spedition",
    image: dexell,
  },
  {
    value: dianthus,
    label: "Dianthus",
    image: dianthus,
  },
  {
    value: donatiro,
    label: "Donatiro",
    image: donatiro,
  },
  {
    value: drumar,
    label: "Drumar Ovy Construct",
    image: drumar,
  },
  {
    value: dsp,
    label: "DSP Eurotrans",
    image: dsp,
  },
  {
    value: dsv,
    label: "DSV",
    image: dsv,
  },
  {
    value: dumagas,
    label: "Dumagas Transport",
    image: dumagas,
  },
  {
    value: dunca,
    label: "Dunca Expeditii",
    image: dunca,
  },
  {
    value: duvenbeck,
    label: "Duvenbeck Logistik",
    image: duvenbeck,
  },
  {
    value: dya,
    label: "Dya Market",
    image: dya,
  },
  {
    value: east,
    label: "East Cargolog",
    image: east,
  },
  {
    value: ekol,
    label: "Ekol International Logistics",
    image: ekol,
  },
  {
    value: eu_ro,
    label: "EU RO Speed",
    image: eu_ro,
  },
  {
    value: eurohaul,
    label: "Eurohaul Services",
    image: eurohaul,
  },
  {
    value: european_tractioner,
    label: "European Tractioner Specialist",
    image: european_tractioner,
  },
  {
    value: evan,
    label: "Evan Speed Logistic",
    image: evan,
  },
  {
    value: ew,
    label: "Ew Logistics",
    image: ew,
  },
  {
    value: exclusiv,
    label: "Exclusiv Car Trading",
    image: exclusiv,
  },
  {
    value: express,
    label: "Express Euroscan",
    image: express,
  },
  {
    value: filadelfia,
    label: "Filadelfia",
    image: filadelfia,
  },
  {
    value: filip,
    label: "Filip",
    image: filip,
  },
  {
    value: fir,
    label: "Fir Express",
    image: fir,
  },
  {
    value: first,
    label: "First Deli Group",
    image: first,
  },
  {
    value: frigorificos,
    label: "Frigorificos Daniel",
    image: frigorificos,
  },
  {
    value: galedo,
    label: "Galedo",
    image: galedo,
  },
  {
    value: gatt,
    label: "Gatt Transport & Logistik",
    image: gatt,
  },
  {
    value: giro,
    label: "Giro",
    image: giro,
  },
  {
    value: gopet,
    label: "Gopet",
    image: gopet,
  },
  {
    value: gp,
    label: "GP Intermodal",
    image: gp,
  },
  {
    value: gribex,
    label: "Gribex",
    image: gribex,
  },
  {
    value: grimar,
    label: "Grimar Auto Cezar",
    image: grimar,
  },
  {
    value: gruber,
    label: "Gruber Logistics",
    image: gruber,
  },
  {
    value: gs,
    label: "GS Capital",
    image: gs,
  },
  {
    value: hagero,
    label: "Hagero",
    image: hagero,
  },
  {
    value: hartmann,
    label: "Hartmann",
    image: hartmann,
  },
  {
    value: hedvig,
    label: "Hedvig Impex",
    image: hedvig,
  },
  {
    value: hermes,
    label: "Hermes Forwards SRL",
    image: hermes,
  },
  {
    value: hessers,
    label: "Hessers",
    image: hessers,
  },
  {
    value: hoedlmayr,
    label: "HOEDLMAYR Lazar Romania",
    image: hoedlmayr,
  },
  {
    value: ib,
    label: "IB Cargo",
    image: ib,
  },
  {
    value: inredning,
    label: "Inredning",
    image: inredning,
  },
  {
    value: alexander,
    label: "International Alexander",
    image: alexander,
  },
  {
    value: lazar,
    label: "International Lazar Company",
    image: lazar,
  },
  {
    value: isctr,
    label: "ISCTR",
    image: isctr,
  },
  {
    value: kaufland,
    label: "Kaufland",
    image: kaufland,
  },
  {
    value: kefren,
    label: "Kefren Expres",
    image: kefren,
  },
  {
    value: klg,
    label: "KLG Europe Logistics",
    image: klg,
  },
  {
    value: kojex,
    label: "Kojex Trans",
    image: kojex,
  },
  {
    value: kpmg,
    label: "KPMG",
    image: kpmg,
  },
  {
    value: laguna,
    label: "Laguna Technology",
    image: laguna,
  },
  {
    value: lg,
    label: "LG Trans",
    image: lg,
  },
  {
    value: lkw,
    label: "Lkw Walter",
    image: lkw,
  },
  {
    value: luar,
    label: "Luar",
    image: luar,
  },
  {
    value: mcms,
    label: "M.C.M.S. Bota",
    image: mcms,
  },
  {
    value: mg,
    label: "M.G. EuroTransport Logistik",
    image: mg,
  },
  {
    value: mainfreight,
    label: "Mainfreight",
    image: mainfreight,
  },
  {
    value: maioma,
    label: "Maioma Logistics",
    image: maioma,
  },
  {
    value: man,
    label: "Man Transport Service",
    image: man,
  },
  {
    value: mara,
    label: "Mara Freight",
    image: mara,
  },
  {
    value: marvi,
    label: "Marvi Logistic",
    image: marvi,
  },
  {
    value: marvicon,
    label: "Marvicon",
    image: marvicon,
  },
  {
    value: mastogaf,
    label: "Mastogaf",
    image: mastogaf,
  },
  {
    value: maxim,
    label: "Maxim Invest",
    image: maxim,
  },
  {
    value: mega,
    label: "Mega Image",
    image: mega,
  },
  {
    value: minet,
    label: "Minet Logistics SRL",
    image: minet,
  },
  {
    value: minumar,
    label: "Minumar",
    image: minumar,
  },
  {
    value: mirinec,
    label: "Mirinec",
    image: mirinec,
  },
  {
    value: ms,
    label: "MS Project",
    image: ms,
  },
  {
    value: natylena,
    label: "Natylena Logistic",
    image: natylena,
  },
  {
    value: nett,
    label: "Nett 95 Impex",
    image: nett,
  },
  {
    value: obermair,
    label: "Obermair Sondertransporte",
    image: obermair,
  },
  {
    value: one,
    label: "One Hub Management",
    image: one,
  },
  {
    value: oprean,
    label: "Oprean",
    image: oprean,
  },
  {
    value: optim,
    label: "Optim Cargo Sped",
    image: optim,
  },
  {
    value: oramar,
    label: "Ormar Com",
    image: oramar,
  },
  {
    value: orient,
    label: "Orient SRL",
    image: orient,
  },
  {
    value: orkla,
    label: "Orkla Foods",
    image: orkla,
  },
  {
    value: orzan,
    label: "Orzan SpedT",
    image: orzan,
  },
  {
    value: oxygen,
    label: "Oxygen Logistic",
    image: oxygen,
  },
  {
    value: pall,
    label: "Pall Ex",
    image: pall,
  },
  {
    value: paritrans,
    label: "Paritrans",
    image: paritrans,
  },
  {
    value: pdd,
    label: "PDD Denmark Logistic",
    image: pdd,
  },
  {
    value: petco,
    label: "Petco",
    image: petco,
  },
  {
    value: queen,
    label: "Queen Trans",
    image: queen,
  },
  {
    value: ragioneasa,
    label: "Raginoasa Transimpex",
    image: ragioneasa,
  },
  {
    value: ravitex,
    label: "Ravitex",
    image: ravitex,
  },
  {
    value: ravy,
    label: "Ravy Prest",
    image: ravy,
  },
  {
    value: rcc,
    label: "RCC Cargo",
    image: rcc,
  },
  {
    value: renylog,
    label: "Renylog",
    image: renylog,
  },
  {
    value: rhv,
    label: "RHV Trucks Spedition",
    image: rhv,
  },
  {
    value: rolling,
    label: "Rolling Tons",
    image: rolling,
  },
  {
    value: romdyl,
    label: "Romdyl Spedition",
    image: romdyl,
  },
  {
    value: romfracht,
    label: "Romfracht",
    image: romfracht,
  },
  {
    value: romstyl,
    label: "Romstyl Impex",
    image: romstyl,
  },
  {
    value: rontransmar,
    label: "Rontransmar",
    image: rontransmar,
  },
  {
    value: rostel,
    label: "Rostel",
    image: rostel,
  },
  {
    value: routier,
    label: "Routier European Transport",
    image: routier,
  },
  {
    value: rowo,
    label: "ROWO Spedition SRL",
    image: rowo,
  },
  {
    value: rupin,
    label: "Rupin",
    image: rupin,
  },
  {
    value: sgs,
    label: "Safe Guard Spedition",
    image: sgs,
  },
  {
    value: safir,
    label: "Safir",
    image: safir,
  },
  {
    value: salmex,
    label: "Salmex",
    image: salmex,
  },
  {
    value: sandor,
    label: "Sandor Trans",
    image: sandor,
  },
  {
    value: schaffer,
    label: "Schaffer Consulting",
    image: schaffer,
  },
  {
    value: scuter,
    label: "Scuter Prof",
    image: scuter,
  },
  {
    value: sennder,
    label: "Sennder",
    image: sennder,
  },
  {
    value: sima,
    label: "Sima",
    image: sima,
  },
  {
    value: skiptrans,
    label: "Skiptrans",
    image: skiptrans,
  },
  {
    value: sms,
    label: "SMS Trans",
    image: sms,
  },
  {
    value: speed,
    label: "Speed Line Impex",
    image: speed,
  },
  {
    value: step,
    label: "Step One",
    image: step,
  },
  {
    value: tandem,
    label: "Tandem Intertrans",
    image: tandem,
  },
  {
    value: tarm,
    label: "Tarm Freight Com",
    image: tarm,
  },
  {
    value: tdt,
    label: "TDT Trans Logistic SRL",
    image: tdt,
  },
  {
    value: ten,
    label: "Ten Trans",
    image: ten,
  },
  {
    value: theo,
    label: "Theo & Wenzel SRL",
    image: theo,
  },
  {
    value: timmy,
    label: "Timmy Freight",
    image: timmy,
  },
  {
    value: total,
    label: "Total NSA",
    image: total,
  },
  {
    value: total2,
    label: "Total NSA 2",
    image: total2,
  },
  {
    value: tramos,
    label: "Tramos Logistic",
    image: tramos,
  },
  {
    value: trans_logistic,
    label: "Trans Logistic N.A.D.",
    image: trans_logistic,
  },
  {
    value: trans_men,
    label: "Trans Men 86",
    image: trans_men,
  },
  {
    value: trans_neco,
    label: "Trans Neco",
    image: trans_neco,
  },
  {
    value: trans_orza,
    label: "Trans Orza",
    image: trans_orza,
  },
  {
    value: trans_tur,
    label: "Trans Tur Ardelean",
    image: trans_tur,
  },
  {
    value: transagal,
    label: "Transagal Logistic",
    image: transagal,
  },
  {
    value: transfabi,
    label: "Transfabi Log",
    image: transfabi,
  },
  {
    value: transmec,
    label: "Transmec Group",
    image: transmec,
  },
  {
    value: trans_pink,
    label: "Transpink",
    image: trans_pink,
  },
  {
    value: transportes,
    label: "Transportes Filip",
    image: transportes,
  },
  {
    value: transsami,
    label: "Transsami",
    image: transsami,
  },
  {
    value: trucksters,
    label: "Trucksters",
    image: trucksters,
  },
  {
    value: tutu,
    label: "Tutu Trans",
    image: tutu,
  },
  {
    value: united,
    label: "United Euro Spedition",
    image: united,
  },
  {
    value: uni,
    label: "Unistrans Logistics",
    image: uni,
  },
  {
    value: vectra,
    label: "Vectra International",
    image: vectra,
  },
  {
    value: vio,
    label: "Vio Transgrup",
    image: vio,
  },
  {
    value: way,
    label: "Way Farer",
    image: way,
  },
  {
    value: wise,
    label: "Wise",
    image: wise,
  },
  {
    value: world,
    label: "World Mediatrans",
    image: world,
  },
  {
    value: a_c_speed_line,
    label: "A & C Speed Line",
    image: a_c_speed_line,
  },
  {
    value: ac_mobile,
    label: "AC Mobile",
    image: ac_mobile,
  },
  {
    value: aigle,
    label: "Aigle Group",
    image: aigle,
  },
  {
    value: alfa,
    label: "Alfa Car",
    image: alfa,
  },
  {
    value: altec,
    label: "Altec Logistic SRL",
    image: altec,
  },
  {
    value: atlas,
    label: "Atlas Imobiliare",
    image: atlas,
  },
  {
    value: blema,
    label: "Blema Trans",
    image: blema,
  },
  {
    value: blue,
    label: "Blue Moon Trucks",
    image: blue,
  },
  {
    value: borgovan,
    label: "Borgovan Trans Impex",
    image: borgovan,
  },
  {
    value: comilga,
    label: "Comilga",
    image: comilga,
  },
  {
    value: csi,
    label: "WoraaaaaCSI Logisticaaaiatrans",
    image: csi,
  },
  {
    value: daralex,
    label: "Daralex",
    image: daralex,
  },
  {
    value: db,
    label: "DB Schenker",
    image: db,
  },
  {
    value: design,
    label: "Design Thinking Society",
    image: design,
  },
  {
    value: energy,
    label: "Energy Fast N&G Transport",
    image: energy,
  },
  {
    value: erk,
    label: "ERK Logistic Impex",
    image: erk,
  },
  {
    value: galilex,
    label: "Galilex Forest",
    image: galilex,
  },
  {
    value: gefco,
    label: "Gefco Romania",
    image: gefco,
  },
  {
    value: heisterkamp,
    label: "Heisterkamp Transport Romania",
    image: heisterkamp,
  },
  {
    value: hydras,
    label: "Hydras",
    image: hydras,
  },
  {
    value: infinity,
    label: "Infinity Comprod",
    image: infinity,
  },
  {
    value: innight,
    label: "Innight Romania Express",
    image: innight,
  },
  {
    value: kraft,
    label: "Kraft Logistik",
    image: kraft,
  },
  {
    value: murano,
    label: "Murano Trans Expres",
    image: murano,
  },
  {
    value: nordman,
    label: "Nordman Baum",
    image: nordman,
  },
  {
    value: ravcar,
    label: "Ravcar",
    image: ravcar,
  },
  {
    value: rhenus,
    label: "Rhenus Logistic",
    image: rhenus,
  },
  {
    value: sam,
    label: "Sam Transport",
    image: sam,
  },
  {
    value: semiramis,
    label: "Semiramis",
    image: semiramis,
  },
  {
    value: sorlimar,
    label: "Sorlimar",
    image: sorlimar,
  },
  {
    value: stadtler,
    label: "Stadtler Trans",
    image: stadtler,
  },
  {
    value: tranzit,
    label: "Tranzit",
    image: tranzit,
  },
];
