import React, { useRef } from "react";
import Select, { createFilter } from "react-select";
import { ref, set } from "firebase/database";
import { db } from "./firebase";
import "./Admin.css";
import { logoOptions } from "./logos";

const Admin = ({ person }) => {
  const hideLogoRef = useRef();

  // useEffect(() => {
  //   if (person.hide_logo === true) {
  //     hideLogoRef.current.checked = true;
  //   }
  // }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const nameVal = e.target.elements.name.value;
    const logoVal = e.target.elements.logo.value;
    const formatVal = e.target.elements.format.value;
    const hideLogoVal = e.target.elements.hide_logo.checked;

    const newValues = {
      name: nameVal,
      logo: logoVal,
      format: formatVal,
      hide_logo: hideLogoVal,
    };

    // if (nameVal !== "") {
    //   newValues.name = nameVal;
    // }

    // if (logoVal !== "") {
    //   newValues.logo = logoVal;
    // }

    // if (formatVal !== "") {
    //   newValues.format = formatVal;
    // }

    set(ref(db, "person"), newValues);
  };

  const formatOptions = [
    { value: "time", label: "Time" },
    { value: "time-30", label: "Time 30" },
    { value: "entrepreneur", label: "Entrepreneur" },
  ];

  // const oldFormatLabel = "" + person?.format?[0].toUpperCase() + person?.format?.slice(1);

  if (!person) {
    return <p>Loading</p>;
  }

  return (
    <div className="admin">
      <form onSubmit={handleSubmit}>
        {console.log(person)}
        {console.log(
          formatOptions.find(({ value }) => value === person.format)
        )}

        {person?.format ? (
          <>
            <label>Format:</label>
            <Select
              name="format"
              options={formatOptions}
              defaultValue={formatOptions.find(
                ({ value }) => value === person.format
              )}
            />
          </>
        ) : (
          <>
            <label>Format:</label>
            {/* <Select name="format" options={formatOptions} /> */}
          </>
        )}

        <label>Nume:</label>
        <input
          type="text"
          name="name"
          placeholder="Nume"
          defaultValue={person?.name}
        />

        <label>Logo:</label>
        <Select
          name="logo"
          options={logoOptions}
          filterOption={createFilter({
            matchFrom: "any",
            stringify: (option) => `${option.label}`,
          })}
          formatOptionLabel={(logo) => (
            <div className="logo-option">
              <img src={logo.image} alt="logo" />
              <span>{logo.label}</span>
            </div>
          )}
        />

        <label>
          <input
            type="checkbox"
            name="hide_logo"
            ref={hideLogoRef}
            defaultChecked={person?.hide_logo}
          />{" "}
          Ascunde logo-ul?
        </label>

        <button type="submit">Modifică</button>
      </form>
    </div>
  );
};

export default Admin;
