import React from "react";
import logos_white from "./assets/images/logos-white.svg";
import kopilot from "./assets/images/kopilot.svg";
import time from "./assets/images/time.svg";
import gif from "./assets/images/gif.gif";

const Home2 = ({ person }) => {
  return (
    <div id="wrapper" className="home2">
      <div class="gif_wrapper">
        <img src={gif} alt="gif" />
      </div>
      <div id="main">
        <h1>
          <em className="justify-content-center white white-title">
            KOPILOT:APP <span>OF THE</span>YEAR
          </em>
          <br />
          <span>time</span>
          <br />
          <em className="justify-content-end">
            <span>For</span>
            <img src={logos_white} alt="logos" />
          </em>
        </h1>

        <div className="flex">
          <div className="flex-basis-small">
            <div>
              <h4>
                <strong>
                  Oameni din <br />
                  spatele
                  <br />
                  business-urilor
                  <br />
                  de succes:
                </strong>
              </h4>
            </div>

            <div>
              <h3 className="red" id="name">
                <span
                  dangerouslySetInnerHTML={{
                    __html: person?.name?.replace(" ", "<br />"),
                  }}
                ></span>
                <br />
                {!person.hide_logo && person.logo && (
                  <img src={person.logo} alt="logo" />
                )}
              </h3>
            </div>

            <div>
              <img src={kopilot} alt="kopilot" className="kopilot" />
              <h4>
                <strong>
                  Aplicația care
                  <br />
                  reduce stresul
                  <br />
                  din industria
                  <br />
                  transporturilor.
                </strong>
              </h4>
              <p className="white">
                Alimentezi mereu <br />
                la cel mai mic preț!
              </p>
            </div>

            <div>
              <img src={time} alt="time" className="time" />
              <h4>
                <strong>
                  Timpul nu <br />
                  așteaptă.
                </strong>
              </h4>
              <p className="white">
                Digitalizarea
                <br />
                în transporturi
                <br />
                este crucială.
                <br />
                Află cum Smart <br />
                și DKV transformă
                <br />
                afacerile clienților.
              </p>
            </div>
          </div>

          <div className="align-right">
            <div>
              <h4 className="red mt">
                Prețul <br />
                carburantului <br />
                contează!
              </h4>
              <h4 className="white">
                Cum a reușit <br />
                o aplicație să <br />
                reducă costurile <br />
                cu până la <br />
                5000€/camion <br />
                pe an?
              </h4>
            </div>

            <div>
              <h4 className="white">
                E TIMPUL PENTRU
                <br />
                URMĂTORUL NIVEL <br />
                ÎN TRANSPORTURI.
              </h4>
              <p className="white">
                DKV, un pionier în <br />
                carduri de carburanți <br />
                s-a unit cu SMART <br />
                DIESEL, un brand cu <br />
                rădăcini autohtone,
                <br />
                rezultând Smart Diesel
                <br />
                part of DKV.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home2;
