import React from "react";
import logos from "./assets/images/logos.svg";
import kopilot from "./assets/images/kopilot.svg";
import gif from "./assets/images/gif.gif";

const Home3 = ({ person }) => {
  // {JSON.stringify(person, null, 2)}

  return (
    <div id="wrapper" className="home3">
      <div class="gif_wrapper">
        <img src={gif} alt="gif" />
      </div>
      <div id="main">
        <h1>
          <em className="justify-content-end supratitle">
            <div>
              <strong>RESILIENCE:</strong> Cum să transformi provocările în
              oportunități
            </div>
            <div>
              <strong>GROWTH:</strong> Cum să crești un business de transport
            </div>
            <div>
              <strong>LEADERSHIP:</strong> Cum să atragi oameni care gândesc
              proactiv
            </div>
            <img src={logos} alt="logos" />
          </em>
          <span className="title">Entrepreneur</span>
          <br />
          <em className="subtitle">in Transport</em>
        </h1>

        <div className="flex">
          <div className="flex-basis-small">
            <div>
              <h1 className="black">
                CINE <br />
                ESTE <br />
                SMART <br />
                DIESEL <br />
                PART <br />
                OF DKV?
              </h1>
              <p className="black">
                Din 2021, Smart DIESEL <br />
                s-a unit cu DKV Mobility <br />
                rezultând cea mai dinamică <br />
                și inovativă companie de <br />
                carduri de carburanți și <br />
                servicii suport, din Romania.
              </p>
            </div>

            <div>
              <h2 className="thin white">100+</h2>
              <p>
                Smart Diesel part of DKV <br />
                înseamnă peste 100 de <br />
                oameni cu experiență <br />
                relevantă în industrie.
              </p>
            </div>
          </div>

          <div className="align-right">
            <div className="bubble">
              <div className="bubble-inner">
                <span>
                  Alimentează mereu <strong>la cel mai mic preț!</strong>
                </span>
              </div>
              <img src={kopilot} alt="kopilot" className="kopilot" />
              <h4 className="white">
                Aplicația <br />
                anului în <br />
                Transporturi
              </h4>
            </div>
            <div className="name-wrapper">
              <span
                dangerouslySetInnerHTML={{
                  __html: person?.name?.replace(" ", "<br />"),
                }}
              ></span>
              {person.hide_logo !== true && person.logo && (
                <h3 id="name">
                  <img src={person.logo} alt="logo" />
                </h3>
              )}
              <h4>
                Oamenii care <br />
                fac diferența <br />
                în transporturile <br />
                din România.
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home3;
