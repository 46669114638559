import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ref, onValue } from "firebase/database";
import { db } from "./firebase";
import Admin from "./Admin";
import Home1 from "./Home1";
import Home2 from "./Home2";
import Home3 from "./Home3";
import "./App.css";

const App = () => {
  const [person, setPerson] = useState({});

  useEffect(() => {
    const personRef = ref(db, "person");
    onValue(personRef, (snapshot) => {
      setPerson(snapshot.val());
    });
  }, []);

  if (!person) return null;
  console.log(person);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              person?.format === "entrepreneur" ? (
                <Home3 person={person} />
              ) : person?.format === "time" ? (
                <Home2 person={person} />
              ) : (
                <Home1 person={person} />
              )
            }
          />
          <Route path="/1" element={<Home1 person={person} />} />
          <Route path="/2" element={<Home2 person={person} />} />
          <Route path="/3" element={<Home3 person={person} />} />
          <Route path="/admin" element={<Admin person={person} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
